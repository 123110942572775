<template>
	<div>
		<b-modal
			id="modal-edit-auto-withdrawal-limit"
			cancel-variant="outline-secondary"
			:ok-title="$t('Submit')"
			:cancel-title="$t('Close')"
			ok-variant="primary"
			modal-class="modal-primary"
			centered
			size="md"
			:title="$t('Update auto withdrawal limit')"
			@ok="confirmEditAutoWithdrawalLimit"
			@hidden="resetModal()"
		>
			<b-overlay
				:show="show"
				variant="transparent"
				no-wrap
			/>
			<!-- form -->
			<validation-observer ref="editAutoWithdrawalLimit">
				<b-form
					ref=""
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationFormAutoWithdrawalLimit"
				>
					<!-- Field: Limit -->
					<b-form-group
						label-for="limit"
					>
						<label class="mb-1"><span class="text-danger">*</span> {{ $t('Limit') }}</label>
						<validation-provider
							#default="{ errors }"
							name="limit"
							rules="required|numeric"
						>
							<b-form-input
								id="name"
								v-model="autoWithdrawalLimitData.limit"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								placeholder="Enter Limit"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<!-- Auto Type -->
					<validation-provider
						#default="{ errors }"
						name="auto_type"
						rules="required"
					>
						<b-form-group
							label-for="autoType"
						>
							<label class="mb-1">{{ $t('Type') }}</label>
							<v-select
								v-model="autoWithdrawalLimitData.auto_type.id"
								:options="autoTypeOptions"
								:reduce="val => val.id"
								label="name"
								input-id="autoType"
								disabled
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</b-form-group>
					</validation-provider>

					<!-- Currencies -->
					<validation-provider
						#default="{ errors }"
						name="currencies"
						rules="required"
					>
						<b-form-group
							label-for="currencies"
						>
							<label class="mb-1">{{ $t('Currency') }}</label>
							<v-select
								v-model="autoWithdrawalLimitData.currency"
								:clearable="false"
								:filterable="false"
								:options="currencyOptions"
								:reduce="val => val.value"
								label="value"
								input-id="currencies"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</b-form-group>
					</validation-provider>
				</b-form>
			</validation-observer>

		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BOverlay, BFormSpinbutton, BFormRadio, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
// import paymentTypeStoreModule from './paymentTypeStoreModule'

export default {
	components: {
		BOverlay, BFormSpinbutton, BFormRadio, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		autoTypeOptions: {
			type: Array,
			default: null,
		},
		currencyOptions: {
			type: Array,
			default: null,
		},
		autoWithdrawalLimitDetail: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			autoWithdrawalLimitData: {
				auto_withdrawal_id: '',
				limit: '',
				auto_type: '',
				currency: '',
			},
			show: false,
		}
	},
	watch: {
		async autoWithdrawalLimitDetail(newVal) {
			if (newVal) {
				this.show = true
				await this.fillAutoWithdrawalLimitData(newVal)
				this.show = false
			}
		},
	},
	mounted() {
	},
	created() {
	},
	destroyed() {
	},
	methods: {
		validationFormAutoWithdrawalLimit() {
			this.$refs.editAutoWithdrawalLimit.validate().then(success => {
				if (success) {
					store.dispatch('payment-auto-withdrawal/editAutoWithdrawal', this.autoWithdrawalLimitData)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code === '00') {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										setTimeout(() => {
											this.$bvModal.hide('modal-edit-auto-withdrawal-limit')
											this.resetModal()
										}, '500')
									})
									this.$emit('refetch-data')
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		fillAutoWithdrawalLimitData(data) {
			this.autoWithdrawalLimitData.auto_withdrawal_id = data.auto_withdrawal_id
			this.autoWithdrawalLimitData.limit = parseFloat(data.limit)
			this.autoWithdrawalLimitData.auto_type = data.auto_type
			this.autoWithdrawalLimitData.currency = data.currency
		},
		resetModal() {
			this.$emit('update:autoWithdrawalLimitDetail', null)
			this.autoWithdrawalLimitData.limit = ''
			this.autoWithdrawalLimitData.auto_type = ''
			this.autoWithdrawalLimitData.currency = ''
		},
		confirmEditAutoWithdrawalLimit(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormAutoWithdrawalLimit()
		}
	},
	setup() {
		// if (!store.hasModule('payment-type')) store.registerModule('payment-type', paymentTypeStoreModule)
		return {
		}
	},
}
</script>